.buttons-pc {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1000;
}

.buttons-phone {
    position: absolute;
    bottom: 50px;
    right: 20px;
    z-index: 1000;
    visibility: collapse;
}

.leaflet-tile-pane{
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

@media only screen and (max-width: 650px){
    .buttons-pc {
        visibility: collapse;
    }
    .buttons-phone{
        visibility: visible;
    }
}
