.App {
  text-align: center;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root{
  height: 100dvh;
}

.leaflet-container {
  height: 100dvh;
}